export default function(state = {}, action){
    switch(action.type){
        case "game": 
            return {...state, game: action.payload }
        case "playHistory": 
            return {...state, playHistory: action.payload }
        case "playerSearch": 
            return {...state, playerSearch: action.payload }
        case "playerCards": 
            return {...state, playerCards: action.payload }
        case "winnersInfo": 
            return {...state, winnersInfo: action.payload }
        case "newCardSign":  
            return {...state, newCardSign: action.payload }            
        case "undoLastCardAction":  
            return {...state, undoLastCardAction: action.payload }
        case "acceptWinnersAction":  
            return {...state, acceptWinnersAction: action.payload }
        case "newUserEvent":  
            return {...state, newUserEvent: action.payload }
        case "onNewCardEvent":  
            return {...state, onNewCardEvent: action.payload }
        case "onInscription":  
            return {...state, onInscription: action.payload }
        case "onUserTables":  
            return {...state, onUserTables: action.payload }
        case "onTableInscription":  
            return {...state, onTableInscription: action.payload }
        case "ON_ALL_CARDS":  
            return {...state, ON_ALL_CARDS: action.payload }
        default: 
            return state;      
    }
}
