import React, {useState, useEffect,} from 'react';
import { useParams, Route } from 'react-router-dom';
import { Form, Row, Col, Input, Typography, Result, InputNumber, Button, Modal,Select, Layout, Divider,Alert, Spin, Table, ColumnGroup,Column,Tag, Space , Search } from 'antd';
import PlayerSearch from './PlayerSearch'
import {useSelector, useDispatch} from 'react-redux'
import { SmileOutlined } from '@ant-design/icons';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import {getUsersTables} from '../redux/actions/ActionGame'
import {getClients, playerInscription, enrollTable} from '../redux/actions/ActionGame'
import AppMenu from './AppMenu';


function EnrollTables() {

    
    const { idUser } = useParams();
    
    const dispatch = useDispatch();
    const [savingInscription, setSavingInscription] = useState(false);
    const [success, setSuccess] = useState(true);
    const [showResult, setShowResult] = useState(false);
    const [mensajeError, setMensageError] = useState("Error")
    const [data, setData] = useState();
    const [id, setId] = useState(0); 
    const [visible, setVisible] = useState(false);
    const [userData, setUserData] = useState();
    const [saveReady, setSaveReady] = useState(false);
    const [inscripcionLoading, setInscripcionLoading] = useState(false);
    const [form] = Form.useForm()

    const gameData = useSelector(state => state.game)

    const { Column, ColumnGroup } = Table;
    useEffect(() => {
        const idOperation = Math.random();
        setId(idOperation);
       dispatch( getUsersTables(idOperation, idUser));
      },[]);

      const handleCancel = () => {
        setVisible( false )//});
        setShowResult(false);
        setSaveReady(true);

      };
  
      const newUserResult = useSelector( (state) => {
        try{
        
            if(state.game.onUserTables){
                if(id){
                    if(id===state.game.onUserTables.idOperation){
                        setData(state.game.onUserTables.result.tables)
                        console.log('state.game.onUserTables: ', state.game.onUserTables);
                        setId(0)
                    }
                   
                }
                
            }
            if(state.game.onTableInscription){
                if(id){
                    if(id===state.game.onTableInscription.idOperation){
                        //setData(state.game.onInscription)
                        setInscripcionLoading(false)
                        setSavingInscription(false)
                        //setVisible(false)
                        console.log('state.game.onTableInscription: ', state.game.onTableInscription);
                        setSaveReady(true)
                        setId(0)
                        setShowResult(true);
                        setSuccess(state.game.onTableInscription.result)
                    }
                   
                }
                
            }


           
        }catch{
            console.log("Control de recurrenceia");
        }finally{
            return {
                result : state.onNewCardEvent
            }
        }
    });
   
    /// GUARDAR INSCRIPCION 
    const tableInscription = (record) => {
        const idOperation = Math.random();
            setId(idOperation);
            const values = form.getFieldsValue();
          //user: {montoPagado: 100, descripcionInscripcion: "Conekta OXXO", idPartida: 1}
         //userData: {idcliente: 134, counttotalcartas: 1, nombre: "Felipe Garza
          console.log('userData: ', userData);
            setInscripcionLoading(true)
            dispatch(enrollTable(idOperation, idUser, values.user.idPartida, record.idtabla))
            
          console.log('values: ', values);
          
        setSaveReady(false)


        setSavingInscription(true);
       
        // 
    };
    const onClose = () => {
      setVisible(false);
    };

    
    const changePartida=() =>{
        const values = form.getFieldsValue();
        if(values.user.idPartida > 0 ){
            setSaveReady(true);
        }
    }
    const onErrorAccept= () =>{
        setSaveReady(true);
        setShowResult(false);
     }
     const onClearForm= () =>{
        setSaveReady(true);
       setShowResult(false);
     }
    return (
        <Col span={24}>
             <AppMenu></AppMenu>
             <Col push={2} span={22} >
                    <br></br>
                    <Row >
                        <Col span={12}>
                            <h1 >Listado general de tablas para inscripcion a partidas</h1>
                        </Col>
                        <Col push={6} span={2}>
                            <Button push={12} type="primary" disabled={!saveReady}>Nueva tabla</Button>
                        </Col>
                    </Row>
                    <Col span={20}>
                        <Divider ></Divider>
                    </Col>
                        <Form form={form} size="large" span={5} >
                                    
                                  
                                    <Form.Item 
                                    
                                        name={['user', 'idPartida']} 
                                        label="Selecciona la partida a inscribir" 
                                        rules={[
                                        {
                                            type: 'number',
                                            min: 0,
                                            max: 10000, required: true
                                        },
                                        ]}
                                    >
                                        <InputNumber onChange={changePartida} step="1" defaultValue="15" value="15" style={{borderColor:'#095cb5'}}/>
                                    </Form.Item>
                                    {
                                        (showResult)? 
                                        (success === false ) ? 
                                        <Result    status="error"
                                        title={"No se puede inscribir una tabla ya inscrita : "+mensajeError}
                                        extra={<Button type="primary" onClick={handleCancel}>Aceptar</Button>}
                                    />
                                    :
                                            <Result
                                                icon={<SmileOutlined />}
                                                title="Tabla inscrita con exito !"
                                                extra={<Button type="primary" onClick={handleCancel}>Aceptar</Button>}
                                            />
                                    : <div></div>
                                    }
                                </Form>
                
                    {
                  (inscripcionLoading) ? <Spin tip="Cargando...">
                        <Alert
                        message="Insertado tabla"
                        description="Espera por favor"
                        type="info"
                        />
                    </Spin> : <div></div>
                        
                    }
                    <Row >
                        <Col span={20}>
                            <Table dataSource={data} pagination={{ pageSize: 100 }} size="large">
                                <Column title="ID Tabla" dataIndex="idtabla" key="idtabla"/>
                                <Column title="ID Cliente" dataIndex="idcliente"  key="idcliente"/> 
                                <Column title="Nombre" dataIndex="nombre"   key="nombre"/>
                                <Column title="Numero de tabla" dataIndex="count" key="count"  width="30%" />
                                <Column
                                title="Acciones" width="30%" 
                                key="action"
                                render={(text, record) => (
                                    <Space size="middle">
                                        <a disabled={!saveReady}>Cancelar inscripcion</a>
                                        <Button disabled={!saveReady} type="primary" onClick={() => tableInscription (record) }>Inscribir a partida</Button>
                                    </Space>
                                )}
                                />
                            </Table>
                        </Col>
                    </Row>
            </Col>
        </Col>
        
        
    )
}


export default EnrollTables
