import React, {useState, useEffect,} from 'react';
import { useParams, Route } from 'react-router-dom';
import { Form, Row, Col, Input, Typography, Result, InputNumber, Button, Modal,Select, Layout, Divider,Alert, Spin, Table, ColumnGroup,Column,Tag, Space , Search } from 'antd';
import PlayerSearch from './PlayerSearch'
import {useSelector, useDispatch} from 'react-redux'
import { SmileOutlined } from '@ant-design/icons';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import {getGame} from '../redux/actions/ActionGame'
import {getClients, playerInscription} from '../redux/actions/ActionGame'
import AppMenu from './AppMenu';


function EnrollUser() {
    const { Text, Link } = Typography;
    const { Option } = Select;
    const { Search } = Input;
    const idGame = 0;
    const dispatch = useDispatch();
    const [savingInscription, setSavingInscription] = useState(false);
    const [idPlay, setIdPlay] = useState(0);
    const [success, setSuccess] = useState(true);
    const [showResult, setShowResult] = useState(false);
    const [mensajeError, setMensageError] = useState("Error")
    const [data, setData] = useState();
    const [id, setId] = useState(0); 
    const [visible, setVisible] = useState(false);
    const [userData, setUserData] = useState();
    const [saveReady, setSaveReady] = useState(true);
    const [inscripcionLoading, setInscripcionLoading] = useState(false);
    const [form] = Form.useForm()

    const gameData = useSelector(state => state.game)

    const { Column, ColumnGroup } = Table;
    useEffect(() => {
       dispatch( getGame(idGame));
       if(gameData.game){
            setIdPlay( gameData.game.idjuego);
            console.log('gameData.game.idjuego: ', gameData.game.idjuego);
        }
      },[]);
    // ************************************************************************************
    /// GUARDAR INSCRIPCION 
      const handleOk = () => {
            const idOperation = Math.random();
            setId(idOperation);
            const values = form.getFieldsValue();
          //user: {montoPagado: 100, descripcionInscripcion: "Conekta OXXO", idPartida: 1}
         //userData: {idcliente: 134, counttotalcartas: 1, nombre: "Felipe Garza
          console.log('userData: ', userData);
            setInscripcionLoading(true)
            dispatch(playerInscription(idOperation, userData.idcliente, values.user.montoPagado , values.user.descripcionInscripcion , values.user.idPartida))
            
          console.log('values: ', values);
          
        setSaveReady(false)


        setSavingInscription(true);
       
        // 
       
      };
    // ************************************************************************************

      const handleCancel = () => {
        setVisible( false )//});
        setShowResult(false);
        setSaveReady(true);
        form.resetFields();

      };
  
      const newUserResult = useSelector( (state) => {
        try{
            if(state.game.playerSearch){
                if(id){
                    if(id===state.game.playerSearch.idOperation){
                        setData(state.game.playerSearch)
                        console.log('state.game.playerSearch: ', state.game.playerSearch);
                        setId(0)
                    }
                   
                }
                
            }

            if(state.game.onInscription){
                if(id){
                    if(id===state.game.onInscription.idOperation){
                        //setData(state.game.onInscription)
                         
                        setSavingInscription(false)
                        //setVisible(false)
                        console.log('state.game.onInscription: ', state.game.onInscription);
                        setSaveReady(!state.game.onInscription.result)
                        setId(0)
                        setShowResult(true);
                        setSuccess(state.game.onInscription.result)
                    }
                   
                }
                
            }


           
        }catch{
            console.log("Control de recurrenceia");
        }finally{
            return {
                result : state.onNewCardEvent
            }
        }
    });
    const options = [
        { label: 'Tabla 1 (566)', value: 568 },
        { label: 'Tabla 2 (567)', value: 569 },
        { label: 'Tabla 3 (568)', value: 570 },
        { label: 'Tabla 4 (569)', value: 571 }        
      ];
    
    const showDrawer = (record) => {

        console.log('record: ', record);
        setUserData(record)
        setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };

      const onSearchs = (value) => {
        if(value && value != ""){
            const idOperation = Math.random();
        setId(idOperation);
            dispatch( getClients(idOperation,value));
        }
    };
    const onErrorAccept= () =>{
        setSaveReady(true);
        setShowResult(false);
     }
     const onClearForm= () =>{
        form.resetFields();
        setSaveReady(true);
       setShowResult(false);
     }
    return (
        <Col span={24}>
             <AppMenu></AppMenu>
             <Col push={2} span={24} >
                    <br></br>
                    <Row >
                        <Col span={12}>
                            <h1 >Listado general de clientes</h1>
                        </Col>
                        <Col push={6} span={2}>
                            <Button push={12} type="primary" >Nueva persona</Button>
                        </Col>
                    </Row>
                    <Col span={20}>
                        <Divider ></Divider>
                    </Col>
                
                        <Col span={8}>
                        <Search
                            placeholder="Buscar por nombre(s)"
                            enterButton="Buscar"
                            defaultValue="%"
                            size="large"
                            onSearch={onSearchs}
                            /> 
                        </Col>
                        <br></br>
                
                    
                    <Row >
                        <Col span={20}>
                        
                            <Table dataSource={data} pagination={{ pageSize: 100 }} size="large">
                                <Column title="ID" dataIndex="idcliente" key="idcliente" />
                                <Column title="Nombre" dataIndex="nombre" width="60%"  key="nombre" />                           
                                <Column title="Tablas " dataIndex="counttotalcartas" key="counttotalcartas" />
                                <Column
                                title="Acciones"
                                key="action"
                                render={(text, record) => (
                                    <Space size="middle">
                                        <a>Ver tablas {record.lastName}</a>
                                        <a>Tablas inscritas por partida </a>
                                        <Button type="primary" onClick={() => showDrawer (record) }>Inscribir a partida</Button>
                                    </Space>
                                )}
                                />
                            </Table>
                        </Col>
                    </Row>
                    <Modal
                        title={"Inscripción de usuario a la partida "}
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel} disabled={!saveReady}>
                            Cancelar
                            </Button>,
                            <Button key="submit" type="primary" loading={savingInscription} disabled={!saveReady} onClick={handleOk}>
                                Inscribir a  {userData?userData.nombre:" "}
                            </Button>
                        ]}>
                            <Col span={20} push={2}>
                                <Form form={form} size="large" >
                                    
                                    <Form.Item
                                    defaultValue={100}
                                    size="large"
                                        name={['user', 'montoPagado']} 
                                        label="Monto pagado"
                                        rules={[
                                        {
                                            type: 'number',
                                            min: 0,
                                            max: 10000, required: true
                                        },
                                        ]}
                                    >
                                        <InputNumber step="100" defaultValue={100}/>
                                    </Form.Item>
                                    <Form.Item name={['user', 'descripcionInscripcion']} label="Forma de pago" rules={[
                                        {
                                        required: true
                                        },
                                        ]}>
                                    <Select
                                        showSearch
                                        style={{ width: 200 }}
                                        placeholder="Selecciona "
                                        optionFilterProp="children"
                                    
                                        filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="Conekta OXXO">Conekta OXXO</Option>
                                        <Option value="Conekta Tarjeta">Conekta tarjeta</Option>
                                        <Option value="Conekta SPEI">Conekta SPEI</Option>
                                        <Option value="Banregio SPEI">Banregio SPEI</Option>
                                        <Option value="Dinamica">Dinamica FB</Option>
                                        <Option value="Promocion">Promocion</Option>
                                        <Option value="Premium">Premium</Option>
                                        <Option value="Reinscripcion">Reinscripcion</Option>
                                    </Select>
                                    </Form.Item>
                                    <Form.Item
                                    
                                        name={['user', 'idPartida']} 
                                        label="PARTIDA" 
                                        
                                        rules={[
                                        {
                                            type: 'number',
                                            min: 0,
                                            max: 10000, required: true
                                        },
                                        ]}
                                    >
                                        <InputNumber step="1" defaultValue="15" value="15"/>
                                    </Form.Item>
                                    {
                                        (showResult)? 
                                        (success === false ) ? 
                                        <Result    status="error"
                                        title={"La inscripción no fue exitosa : "+mensajeError}
                                        //extra={<Button type="primary" onClick={onErrorAccept}>Aceptar</Button>}
                                    />
                                    :
                                            <Result
                                                icon={<SmileOutlined />}
                                                title="Usuario inscrito con exito!"
                                                extra={<Button type="primary" onClick={handleCancel}>Aceptar y cerrar</Button>}
                                            />
                                    : <div></div>
                                    }
                                </Form>
                            </Col>
                            {/*<Col>
                            <Row>
                                <Text>Selecciona las&nbsp; </Text> 
                                <Link href="https://ant.design" target="_blank">
                                    tablas del cliente
                                </Link>
                                <Text> &nbsp; a inscribir </Text>
                            </Row>
                            
                                                <Divider></Divider>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="checkbox-group"  size="large">
                                    <Checkbox.Group options={options} >
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                                    */}
                    </Modal>
            </Col>
        </Col>
        
        
    )
}


export default EnrollUser
