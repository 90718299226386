import React, { Component } from 'react'
import {css} from 'glamor'
import WinnerItemRender from './WinnerItemRender'
export class CardPlayerItemRender extends Component {
    render() {
        //debugger;
        const cardsBoard = this.props.cartaData.carta.map((figura)=>{
                const figuraImagen = {
                    backgroundImage:'url(/img/imgCartas/'+figura.imagencarta+')',
                    '-webkit-background-size': '100%',
                    'background-size': '100%'
                }

                if(figura.enjuego===0 && figura.cantada===0){
                    // Carta no activa y sin cantar
                    return(
                        <div className="clearfix, cartaStyle" style={figuraImagen}>
                             <span className="nombreCartaJugador">{figura.name}</span>
                        </div>
                    )
                }else if(figura.enjuego===0 && figura.cantada===1){
                    return(
                        // Carta no activa y cantada
                        <div className="clearfix, cartaStyle" style={figuraImagen}>
                            <div id="d_cartaSelecionadaNoAplica" className="clearfix">
                            </div>
                            <span className="nombreCartaJugador">{figura.name}</span>
                        </div>
                    )     
                }else if(figura.enjuego===1 && figura.cantada===0){
                    return(
                         // Carta Activa y no cantada 
                         <div className="clearfix, cartaActiva" style={figuraImagen}>
                             <span className="nombreCartaJugador">{figura.name}</span>
                         </div>
                    )         
                }else if(figura.enjuego===1 && figura.cantada===1){
                    return(
                         // Carta Activa y cantada 
                        <div className="clearfix, cartaActiva" style={figuraImagen}>
                            
                            <div className="clearfix, d_cartaSelecionadaAplica">
                            </div>
                            <span className="nombreCartaJugador">{figura.name}</span>
                        </div>
                    )     
                }
                

               

         
        })
        return (
            <div className="clearfix, UITablasJugador">
                {this.props.showWinner === true ? <WinnerItemRender position={this.props.cartaData.count} nombre={this.props.cartaData.ganadornombre} premio={this.props.cartaData.premio} /> : null}
                
                <div className="clearfix, UITablaPlayerStyle">
                {cardsBoard}
               
                <div id="nombreCarta">
                    {
                        this.props.showLogo ? <div style={{width:'80px', float:'left', height:'80px', backgroundSize:'100%', backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundImage:'URL(img/ComposicionLogoFondoMorado.png)'}} ></div> : null
                    }
                    <p style={{float:'left', margin:'25px'}}>
                   {this.props.cartaData.nombre}- {this.props.cartaData.count} 
                    </p>
                
                </div>
                {
                        this.props.showLogo ?  <div style={{position:'relative', width:'100%', height:'100%', backgroundRepeat:'no-repeat', backgroundSize:'100%', backgroundPosition:'center', backgroundImage:'URL(img/ComposicionLogoFondoMorado.png)', opacity: '0.26' }}></div>  : null
                    }
               
                 </div>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 
            </div>
        )
    }
}

export default CardPlayerItemRender
