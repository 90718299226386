import React, {useState, useEffect} from 'react';
import { SmileOutlined } from '@ant-design/icons';
//import 'antd/dist/antd.css';
import 'antd/dist/antd.dark.css';

import { Form, Input, InputNumber, Button, DatePicker,Alert, Spin, Result } from 'antd';
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment';
import { format } from "date-fns";
import { Select } from 'antd';
import { useParams, Route } from 'react-router-dom';
//import packageReducers from './redux/reducers/package-reducers';
import {gameRed} from '../redux/reducers/gameReducer'
import {newUsers} from '../redux/actions/ActionGame'

const { Option } = Select;

  
function NewClients() {

    const newUserResult = useSelector( (state) => {
        try{
            if(state.game.newUserEvent){
                if(id){
                    if(id===state.game.newUserEvent.id){
                        
                        console.log('state.game.newUserEvent: ', state.game.newUserEvent);
                        setShowResult(true);
                        setLoading(false);
                        setSuccess(state.game.newUserEvent.result)
                        if(!state.game.newUserEvent.result){
                            setMensageError( state.game.newUserEvent.msg)
                        }
                        setId(0)
                    }
                }
                
            }
        }catch{
            console.log("Control de recurrenceia");
        }finally{
            return {
                result : state.newUserEvent
            }
        }
       
       
    });
        /*
        state => {
        state.newUserEvent
        console.log('state.newUserEvent: ', state.newUserEvent);
        return state.newUserEvent;
    })*/
    //const [newUserResult, dispatchs] = useReducer(newUserResult, null)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); 
    const [mensajeError, setMensageError] = useState("Error")
    const [success, setSuccess] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [saveReady, setSaveReady] = useState(true);
    const [id, setId] = useState(0); 
    const [form] = Form.useForm()
    
     const onErrorAccept= () =>{
       setSaveReady(true);
       setShowResult(false);
    }
    const onClearForm= () =>{
        form.resetFields();
        setSaveReady(true);
       setShowResult(false);
     }
    const { idGame } = useParams();
    const layout = {
        labelCol: {
          span: 3,
        },
        wrapperCol: {
          span: 18,
        },
      };
      const dateFormat = 'DD/MM/YYYY';
      const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
      const validateMessages = {
        required: '${label} es requerido!',
        types: {
          email: '${label} debe ser un correo!',
          number: '${label} debe ser de tipo numerico!',
        },
        number: {
          range: '${label} Debe estar en el rango de ${min} y ${max}',
        },
        date: '${label} Debe ser una fecha valida'
      };

    const onFinish = (values ) => {
        const userData = values.user ; 
        
        setLoading(true);
        setSaveReady(false);
        //dispatch( newUsers(nombre, apellidos, mail, telefono,fechaNacimiento, perfilCliente, urlFacebook, montoPagado, descripcionInscripcion, idPartida  ));
        //userData.nombre, userData.apellidos, userData.mail, userData.telefono, userData.fechaNacimiento, userData.perfilCliente , userData.urlFacebook, userData.montoPagado, userData.descripcionInscripcion, userData.idPartida 
        var formattedDate = '1990-01-01'
        if(userData.fechaNacimiento){
            var date = new Date(userData.fechaNacimiento);
            console.log('userData.fechaNacimiento: ', userData.fechaNacimiento);
             formattedDate = format(date, "yyyy-MM-dd");
        }
        const idOperation = Math.random();
        setId(idOperation);
        console.log (">> "+  userData.nombre+ "  " +userData.apellidos+ "  " +userData.mail+ "  " +userData.telefono+ "  " +formattedDate+ "  " +userData.perfilCliente + "  " +userData.urlFacebook+ "  " +userData.montoPagado+ "  " +userData.descripcionInscripcion+ "  " +userData.idPartida );
        dispatch( newUsers(idOperation , userData.nombre, userData.apellidos, userData.mail?userData.mail:"", userData.telefono, formattedDate, userData.perfilCliente , userData.urlFacebook?userData.urlFacebook:'', userData.montoPagado, userData.descripcionInscripcion, userData.idPartida  ));
      };
      useEffect(() => {
        if(newUserResult){
             
             console.log('newUserResult: ', newUserResult);
             setLoading(false);

         }
 
 
       },[]);
    return (
        
        <div>
                <h1  {...layout} >Alta de nuevos clientes</h1>
            <Form   form={form} size="large" {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
             
                      {
                        (showResult)? 
                        (success === false ) ? 
                        <Result    status="error"
                        title={"El alta no fue exitosa : "+mensajeError}
                        extra={<Button type="primary" onClick={onErrorAccept}>Aceptar</Button>}
                    />
                      :
                            <Result
                                icon={<SmileOutlined />}
                                title="Usuario insertado con exito!"
                                extra={<Button type="primary" onClick={onClearForm}>Crear otro usuario</Button>}
                            />
                     : <div></div>
                    }
            
                <Form.Item
                    name={['user', 'nombre']}
                    label="Nombre"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['user', 'apellidos']}
                    label="Apellidos"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['user', 'mail']}
                    initialValue={"vacio@sinmail.com"}
                    label="Email"
                    rules={[
                    {
                        type: 'email',
                    },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                        name={['user', 'telefono']}
                        initialValue={987654321}
                        label="Telefono"
                        
                    >
                    <Input maxLength={10}/>
                </Form.Item>
                  
                <Form.Item initialValue={moment('02/06/1980', dateFormat)} 
                    name={['user', 'fechaNacimiento']}
                    label="Fecha de nacimiento"
                    format={dateFormatList}
                    rules={[
                    {
                        type: 'any'
                    },
                    ]}
                >
                    <DatePicker  format={dateFormatList}  />
                </Form.Item>
           
           
                <Form.Item   initialValue={5}
                    name={['user', 'perfilCliente']}
                    label="Perfil de cliente"
                    rules={[
                    {
                        type: 'number',
                        min: 0,
                        max: 10, required: true
                    },
                    ]}
                >
                    <InputNumber  defaultValue="5" />
                </Form.Item>
              
                <Form.Item name={['user', 'urlFacebook']} label="Facebook">
                    <Input />
                </Form.Item>
                <Form.Item
                defaultValue={100}
                    name={['user', 'montoPagado']} 
                    label="Monto pagado"
                    rules={[
                    {
                        type: 'number',
                        min: 0,
                        max: 10000, required: true
                    },
                    ]}
                >
                    <InputNumber step="100" defaultValue={100}/>
                </Form.Item>
                <Form.Item name={['user', 'descripcionInscripcion']} label="Forma de pago" rules={[
                    {
                      required: true
                    },
                    ]}>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Selecciona "
                    optionFilterProp="children"
                
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="Conekta OXXO">Conekta OXXO</Option>
                    <Option value="Conekta Tarjeta">Conekta tarjeta</Option>
                    <Option value="Conekta SPEI">Conekta SPEI</Option>
                    <Option value="Banregio SPEI">Banregio SPEI</Option>
                    <Option value="Dinamica">Dinamica FB</Option>
                    <Option value="Promocion">Promocion</Option>
                    <Option value="Premium">Premium</Option>
                    <Option value="Reinscripcion">Reinscripcion</Option>
                </Select>
                </Form.Item>
                <Form.Item
                 
                    name={['user', 'idPartida']} 
                    label="PARTIDA" 
                    initialValue = {idGame*1}
                    rules={[
                    {
                        type: 'number',
                        min: 0,
                        max: 10000, required: true
                    },
                    ]}
                >
                    <InputNumber step="1" defaultValue="15" value="15"/>
                </Form.Item>
                
                { 
                

                (saveReady) ?<Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
                <Button type="primary" htmlType="Guardar">
                    Inscribir y guardar
                </Button>
            </Form.Item>:<div></div>
              
                               
            }
            {
                  (loading) ? <Spin tip="Cargando...">
                  <Alert
                  message="Insertando usuario"
                  description="Espero por favor"
                  type="info"
                  />
              </Spin> : <div></div>
                   
            }
          
                  </Form>
              
        </div>
    )
}

export default NewClients
