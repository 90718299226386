
import React, {useState, useEffect,} from 'react';
import CardsItemrender from './generic/CardsItemrender';
import {useSelector, useDispatch} from 'react-redux'
import { Form, Row, Col, InputNumber, Button,  Layout, Divider,Alert, Spin, Result } from 'antd';
import {getCards} from '../redux/actions/ActionGame'


const { Header, Footer, Sider, Content } = Layout;

  
  function AllCards() {
    const [cards, setCards] = useState([]);
    const dispatch = useDispatch();
    const [id, setId] = useState(0); 
    
    useEffect(() => {
        const idOperation = Math.random();
        setId(idOperation);
        dispatch( getCards(idOperation));
    },[]);
    const newUserResult = useSelector( (state) => {
        try{
            if(state.game.ON_ALL_CARDS){
                if(id){
                    if(id===state.game.ON_ALL_CARDS.idOperation){
                        
                        redraw(state.game.ON_ALL_CARDS)
                        
                        setId(0)
                    }
                }
                
            }
        }catch{
            console.log("Control de recurrenceia");
        }finally{
            return {
                result : state.onNewCardEvent
            }
        }
       
       
    });
    const redraw = (cartas) => {
        const itemsRender = cartas.map((carta)=>{
            if(carta){

                return(
                    <CardsItemrender key={carta.idcarta} carta={carta}/>
                )
            }
            return null           
        })
        setCards(itemsRender)
    }

      return (
        <Col span={24} style={{
            overflow: 'scroll',
            height: '90%'
          }}>
                {cards}
        </Col>
      )
  }
  
  export default AllCards
  