import React, {useState, useEffect} from 'react';
import { useParams, Route } from 'react-router-dom';
import PlayerSearch from './PlayerSearch'
import {useSelector, useDispatch} from 'react-redux'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import {getGame} from '../redux/actions/ActionGame'

import NewClients from './NewClients';

function AdminOptions() {
    
    const { idGame } = useParams();
    const dispatch = useDispatch();
    const [idPlay, setIdPlay] = useState(0);
    
    const gameData = useSelector(state => state.game)
  
    
    useEffect(() => {
        
       dispatch( getGame(idGame));
       if(gameData.game){
           
            setIdPlay( gameData.game.idjuego);
            console.log('gameData.game.idjuego: ', gameData.game.idjuego);
        }


      },[]);

      console.log(gameData)
      
    return (
        <div>
            <div>
                <h1>Listado de jugadores de la partida : {idGame}</h1>
                <button style={{position:'absolute', right:'10px', top:'10px'}}>Nueva persona</button>
            </div>
            
            <div style={{top:'100px', left:'0px', right:'0px', bottom:'0px', position:'fixed'}}>
                {gameData.game ? <PlayerSearch idPlay={gameData.game.idjuego} notVisible={true}/> : <span>Buscando juego activo</span>}
                
            </div>
            
        </div>
    )
}


export default AdminOptions
