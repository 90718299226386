import React, { Component } from 'react'
import CardPlayerItemRender from './generic/CardPlayerItemRender'

import { bindActionCreators } from 'redux';
import {getPlayerCards} from '../redux/actions/ActionGame'
import {connect} from 'react-redux'

export class PlayerTables extends Component {
    constructor(props){
      super(props);
        this.state = {
          cartasEnJuego : []
        }
    }
    
    render() {
      let tablasUsers = <div></div>;
      if(this.props.playerCards){
        tablasUsers = this.props.playerCards.map((cartaEnJuego)=>{
            return(
                 <CardPlayerItemRender showLogo={this.props.showLogo} key={cartaEnJuego.idtabla} cartaData={cartaEnJuego}></CardPlayerItemRender>
              )
          })
      }
        return (
            <div className="clearfix, UICartasJuegador">
            <p id="d_nombreJugador">
              CARTAS EN JUEGO DE <br></br> {this.props.playerData.nombre.toUpperCase()}
            </p>
            <p id="btn_regresar" className="i_regresar" onClick={this.props.onCloseEvent}>
              ATRAS
            </p>
            <div className="tablaJugador">
              <div id="UIListadoTablasJugador" className="clearfix">
                {tablasUsers}
               
                
              </div>
            </div>
          </div>
        )
    }
}
const mapStateToProps = (state) => {
  return {
    playerCards: state.game.playerCards
  }
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({getPlayerCards}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerTables);
