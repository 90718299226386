import React, { useState } from 'react';
import { Form, Row, Col, InputNumber, Button,  Layout, Divider,Alert, Spin, Result } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { SmileOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from 'react-redux'
import {newCardEvent} from '../redux/actions/ActionGame'
import { useParams, Route } from 'react-router-dom';
import AllCards from './AllCards';
import CardsItemrender from './generic/CardsItemrender';

function TableCreator() {
    const [expand, setExpand] = useState(false);
        const [form] = Form.useForm();
        const { idPlayer } = useParams();
        const { idGame } = useParams();
        const dispatch = useDispatch();


        const [loading, setLoading] = useState(false); 
        const [mensajeError, setMensageError] = useState("Error")
        const [success, setSuccess] = useState(false);
        const [showResult, setShowResult] = useState(false);
        const [saveReady, setSaveReady] = useState(true);
        const [id, setId] = useState(0); 
        const [cardsCollection, setCardsCollection] = useState([]); 

        const changeCard= name => value => {
            
            //cartaUnica = {alias:"VIOLONCELLO 2",idcarta:17,nombre:"EL VIOLONCELLOs",url:"/img/imgCartas/GALLO.png"};
            console.log('cartaUnica: ', value, "OK" , name );
        }
        const newUserResult = useSelector( (state) => {
            try{
                if(state.game.onNewCardEvent){
                    if(id){
                        if(id===state.game.onNewCardEvent.id){
                            
                            console.log('state.game.onNewCardEvent: ', state.game.onNewCardEvent);
                            setShowResult(true);
                            setLoading(false);
                            setSuccess(state.game.onNewCardEvent.result)
                            if(!state.game.onNewCardEvent.result){
                                setMensageError( state.game.onNewCardEvent.msg)
                            }
                            setId(0)
                        }
                    }
                    
                }
                if(state.game.ON_ALL_CARDS){
                    if(state.game.ON_ALL_CARDS != cardsCollection){
                        setCardsCollection(state.game.ON_ALL_CARDS); //     
                    }
                    
                }
            }catch{
                console.log("Control de recurrenceia");
            }finally{
                return {
                    result : state.onNewCardEvent
                }
            }
           
           
        });

        const layout = {
            labelCol: {
              span: 10,
            },
            wrapperCol: {
              span: 16,
            },
          };
          const {  Sider, Content } = Layout;
          const validateMessages = {
            required: '${label} es requerido!',
            types: {
              email: '${label} debe ser un correo!',
              number: '${label} debe ser de tipo numerico!',
            },
            number: {
              range: '${label} Debe estar en el rango de ${min} y ${max}',
            },
            date: '${label} Debe ser una fecha valida'
          };
        const getFields = () => {
            const count = expand ? 17 : 17;
            const children = [];
            for (let i = 1; i < count; i++) {
                let cartaUnica = {alias:"VIOLONCELLO",idcarta:i,nombre:"EL VIOLONCELLO",url:"/img/imgCartas/VIOLONCELLO.png"};
            children.push(
                <Col span={6} key={i}>
                <Form.Item
                    name={`P${i}`}
                    label={`P ${i}`}
                  
                    rules={[
                    {
                        type: 'number',
                        min: 1,
                        max: 54, required: true,
                        message: 'Ingresa un numero de valido'
                        
                    },
                    ]}
                >
                    
                     {/*<CardsItemrender key={cartaUnica.idcarta} carta={cartaUnica}/> onChange={changeCard(cartaUnica)} */ }
                    <InputNumber placeholder="# Figura" />
                </Form.Item>
                </Col>,
            );
            }
            return children;
        };
        const onAleatorio = values => {
            values.aleatorio = 1;

            setLoading(true);
            setSaveReady(false);
            const idOperation = Math.random();
            setId(idOperation);
            values.user = {idJugador : idPlayer, idPartida : idGame}
            values.P1 = 1;
            values.P2= 2;
            values.P3 = 3;
            values.P4 = 4;
            values.P5 = 5;
            values.P6= 6;
            values.P7 = 7;
            values.P8 = 8;
            values.P9 = 9;
            values.P10 = 10;
            values.P11 = 11;
            values.P12 = 12;
            values.P13 = 13;
            values.P14 = 14;
            values.P15 = 15;
            values.P16 = 16;

           dispatch( newCardEvent(idOperation, values));
            console.log('Todo sera aleatorio ', values);
        };
        const onFinish = values => {
            values.aleatorio = 0;
            setLoading(true);
            setSaveReady(false);
            const idOperation = Math.random();
            setId(idOperation);
            dispatch( newCardEvent(idOperation, values));
            //console.log('Received values of form: ', values);
        };
        const onErrorAccept= () =>{
            setSaveReady(true);
            setShowResult(false);
         }
        const onClearForm= () =>{
            form.resetFields();
            setSaveReady(true);
           setShowResult(false);
         }
         
    return (
            <Col span={22} push={1}>
                <Row>
    <h1>Alta de tabla del cliente previamente inscrito</h1>
                    
                </Row>
                <Row>
                <Col span={16}> 
             
                    <Form  {...layout}  size="large"
                        form={form}
                        name="advanced_search"
                        validateMessages={validateMessages}
                        onFinish={onFinish}
                        >
                        <Divider></Divider>
                        <Row span={24} >
                            <Col span={14}> 
                                 <Form.Item
                                        name={['user', 'idJugador']} 
                                        label="Id jugador" 
                                        initialValue = {idPlayer*1}
                                        rules={[
                                        {
                                            type: 'number',
                                            min: 0,
                                            max: 10000, required: true
                                        },
                                        ]}
                                    >
                                        <InputNumber step="1" defaultValue="15" value="15"/>
                                </Form.Item>
                            </Col>
                            <Col  >
                                
                                <Form.Item
                                    
                                        name={['user', 'idPartida']} 
                                        label="Id partida" 
                                        initialValue = {idGame*1}
                                        rules={[
                                        {
                                            type: 'number',
                                            min: 0,
                                            max: 10000, required: true
                                        },
                                        ]}
                                    >
                                        <InputNumber step="1" span={2} defaultValue="15" value="15"/>
                                </Form.Item>
                            </Col>
                   
                            
                        </Row>
                      
                        <Divider></Divider>

                            <Col span={24}>
                                <Row gutter={30}>{getFields()}</Row>
                            </Col>
                        <Divider></Divider>
                        {(saveReady) ? 
                            <Row>                        
                                <Col span={16} style={{ textAlign: 'right' }}>
                                    <Button  htmlType="submit"  onClick={onAleatorio} style={{ margin: '0 8px', background:'green', color:"white", borderColor: "green" }}>
                                            Generar al azar
                                        </Button>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                        form.resetFields();
                                        }}
                                    >
                                        Limpiar
                                    </Button>
                                
                                    <Button type="primary" htmlType="submit"  style={{ margin: '0 8px' }}>
                                        Guardar
                                    </Button>
                                </Col>
                            </Row>
                            : <div></div>
                        }
                        {
                            (loading) ? <Spin tip="Cargando...">
                            <Alert
                            message="Insertando tabla"
                            description="Espera por favor"
                            type="info"
                            />
                        </Spin> : <div></div>
                        }

                        {
                            (showResult)? 
                            (success === false ) ? 
                            <Result    status="error"
                            title={"El alta no fue exitosa : "+mensajeError}
                            extra={<Button type="primary" onClick={onErrorAccept}>Aceptar</Button>}
                           />
                        :
                                <Result
                                    icon={<SmileOutlined />}
                                    title={"Tabla insertada con exito Jugador ID :"+idPlayer+" Partida: "+idGame}
                                    extra={<Button type="primary" onClick={onClearForm}>Crear otra tabla al mismo usuario</Button>}
                                />
                        : <div></div>
                        }

                    </Form>
                </Col>
                
                <Col Col span={8} push={1}>
                    <h2>Cartas disponibles</h2>
                    <AllCards></AllCards>
                </Col>
                </Row>
                
            </Col>
            
       
    )
}

export default TableCreator
