import React, { useState } from 'react';
import { Menu } from 'antd';
import { MailOutlined, TrophyOutlined, UserAddOutlined, TeamOutlined, GroupOutlined } from '@ant-design/icons';


function AppMenu() {
    const { SubMenu } = Menu;

    const [current, setCurrent] = useState('email')
    const handleClick = e => {
        console.log('click ', e);
        setCurrent(current);
      };
    return (
        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
            <Menu.Item key="clients" icon={<TeamOutlined />} setCurrent={this}>
                Clientes e inscripciones
            </Menu.Item>
            <Menu.Item key="newClients" icon={<UserAddOutlined />}>
                Alta de clientes
            </Menu.Item>
            <Menu.Item key="games" icon={<TrophyOutlined />}>
                Clientes por partida
            </Menu.Item>
            <Menu.Item key="newTables" icon={<GroupOutlined />}>
                Crear tablas de clientes
            </Menu.Item>
        </Menu>
    )
}

export default AppMenu

