import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import HeaderGame from './components/HeaderGame'
import HistoryCards from './components/HistoryCards'
import CardSinging from './components/CardSinging'
import GameType from './components/GameType'
import CurrentGameHeader from './components/CurrentGameHeader'

import Winner from './components/Winner'
import PlayerSearch from './components/PlayerSearch'
import PlayerTables from './components/PlayerTables'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'

import { applyMiddleware, createStore } from 'redux'
import packageReducers from './redux/reducers/package-reducers'
import promise from 'redux-promise'
import App from './App'
import { BrowserRouter, Route } from 'react-router-dom'
import AdminOptions from './components/AdminOptions'
import NewClients from './components/NewClients'
import TableCreator from './components/TableCreator'
import EnrollUser from './components/EnrollUser'
import EnrollTables from './components/EnrollTables'

const creteStoreWithMiddleWare = applyMiddleware(promise)(createStore);
  /* <App></App> <Route path="*" component={App}/> */

ReactDOM.render(
                    <Provider store={creteStoreWithMiddleWare(packageReducers)}>
                      <BrowserRouter>
                        <Route path="/ria/:idGame" component={App}/>
                        
                        <Route path="/recording/" component={App}/>
                        <Route path="/EnrollUser/" component={EnrollUser}/>
                        <Route path="/EnrollTables/:idUser" component={EnrollTables}/>
                        
                        <Route path="/admin/:idGame" component={AdminOptions}/>
                        <Route path="/NewClient/:idGame" component={NewClients}/>
                        <Route path="/TableCreator/:idPlayer/:idGame" component={TableCreator}/>
                      </BrowserRouter>      
                      

                    </Provider>
                  
, document.querySelector('#root')
)
